<template>
  <v-row justify="center">
    <v-col cols="12" sm="6">
      <equivalences
        equivalenceType="modules"
        :formatDate="formatDate"
        :getStatusColor="getStatusColor"
        :getStatusIcon="getStatusIcon"
        :getStatusLabel="getStatusLabel"
        :disabled="disabled"
      />
    </v-col>
    <v-col cols="12" sm="6">
      <equivalences
        equivalenceType="langs"
        :formatDate="formatDate"
        :getStatusColor="getStatusColor"
        :getStatusIcon="getStatusIcon"
        :getStatusLabel="getStatusLabel"
      />
    </v-col>
  </v-row>
</template>

<script>
import Equivalences from "@/views/Equivalences.vue";

export default {
  components: { Equivalences },
  props: [
    "formatDate",
    "getStatusColor",
    "getStatusIcon",
    "getStatusLabel",
    "equivalenceType",
    "disabled",
  ],
};
</script>
