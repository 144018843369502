var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{attrs:{"disabled":_vm.disabled}},[_c('div',{staticClass:"mb-5"},[_vm._v(" 1. Sélectionnez les modules pour lesquels vous souhaitez faire une demande d'équivalence. ")]),_c('v-autocomplete',{attrs:{"label":"Modules *","items":_vm.modules.filter(function (m) { return m; }),"item-text":function (item) { return item.label
          ? ((item.label) + " (" + (item.name) + ")")
          : ("Sans titre (" + (item.name) + ")"); },"item-value":"_id","outlined":"","dense":"","multiple":""},model:{value:(_vm.selectedModules),callback:function ($$v) {_vm.selectedModules=$$v},expression:"selectedModules"}}),(_vm.langModuleSelected)?_c('div',{staticClass:"mb-5"},[_c('a',{attrs:{"href":_vm.modules.length &&
        _vm.modules.map(function (m) { return m.department.indexOf('HEG') > -1; }).indexOf(true) >
          -1
          ? '/heg-demande-equivalence-dispense.pdf'
          : '/ing-demande-equivalence.pdf',"target":"_blank"}},[_vm._v("Formulaire pour l'équivalence de langues")])]):_vm._e(),_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"mb-5"},[_vm._v(" 2. Sélectionnez le type d'étude et une explication permettant de justifier les modules sélectionnés. ")]),_c('v-container',{staticClass:"pa-0"},_vm._l((_vm.equivalence.modules),function(module){return _c('v-row',{key:module._id},[_c('v-col',[(
            module &&
            !module.url &&
            module.basemodule &&
            !module.basemodule.url
          )?_c('v-alert',{attrs:{"outlined":"","type":"error","dense":"","text":""}},[_c('div',[_vm._v("Le descriptif de module n'est pas encore disponible.")])]):_vm._e(),(
            (module && module.url && module.validite !== module.year) ||
            (module.basemodule &&
              module.basemodule.url &&
              module.basemodule.validite !== module.basemodule.year)
          )?_c('v-alert',{attrs:{"outlined":"","dense":"","text":"","type":"warning"}},[_c('div',[_vm._v("Le descriptif de module peut être sujet à modifications.")]),_c('div',[_c('a',{attrs:{"href":module.url || module.basemodule.url,"target":"_blank"}},[_vm._v("Descriptif de module (GAPS)")])])]):_vm._e(),(
            (module && module.url && module.validite === module.year) ||
            (module.basemodule &&
              module.basemodule.url &&
              module.basemodule.validite === module.basemodule.year)
          )?_c('v-alert',{attrs:{"outlined":"","dense":"","text":"","type":"success"}},[_c('div',[_vm._v(" Le descriptif de module est valide pour l'année académique, les chances de changements est extrêmement faible (mais toujours possible). ")]),_c('div',[_c('a',{attrs:{"href":module.url || module.basemodule.url,"target":"_blank"}},[_vm._v("Descriptif de module (GAPS)")])])]):_vm._e(),_c('div',[(_vm.equivalence.status === 'complete')?_c('v-select',{attrs:{"outlined":"","dense":"","disabled":"","items":_vm.managerStatuses,"label":"Statut","value":module.managerStatus}}):_vm._e(),_c('v-autocomplete',{attrs:{"label":("Type d'étude pour " + (module.name || module.basemodule.name) + " *"),"items":_vm.studyTypes,"outlined":"","dense":""},model:{value:(module.studyType),callback:function ($$v) {_vm.$set(module, "studyType", $$v)},expression:"module.studyType"}}),_c('v-textarea',{attrs:{"outlined":"","dense":"","label":("Explication pour " + (module.name || module.basemodule.name) + " *")},model:{value:(module.description),callback:function ($$v) {_vm.$set(module, "description", $$v)},expression:"module.description"}})],1)],1)],1)}),1),_c('v-divider',{staticClass:"mb-6"}),_c('div',{staticClass:"mb-5"},[_vm._v(" 3. Téléversez les documents demandés (Vous avez la possibilité de sélectionner plusieurs documents). ")]),_vm._l((_vm.requiredDocuments),function(studyType){return _c('v-file-input',{key:studyType,attrs:{"label":studyType !== 'Autre situation'
        ? studyType === 'Formulaire pour l\'équivalence de langues'
          ? (studyType + " *")
          : ("Justificatif(s) pour " + studyType + " *")
        : ("Justificatif(s) pour " + studyType),"outlined":"","dense":"","value":_vm.getFilename(studyType),"loading":_vm.uploading[studyType],"accept":".pdf","hint":"Fichier au format PDF","truncate-length":"100","multiple":""},on:{"change":function (file) {
        _vm.handleUpload(studyType, file);
      },"click:clear":function($event){return _vm.removeDocument(studyType)}}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }