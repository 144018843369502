var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-weight-light",attrs:{"align":"center"}},[_c('div',{staticClass:"headline mb-10",attrs:{"align":"center"}},[_vm._v(" Équivalences "+_vm._s(_vm.equivalenceType === "langs" ? "langues" : "modules")+" "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([(_vm.disabled)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-lock ")])]}}:null],null,true)},[_c('span',[_vm._v("Hors période d'inscription")])])],1),_c('v-btn',{attrs:{"outlined":"","block":"","color":"primary lighten-2","plain":"","ripple":false,"disabled":_vm.disabled},on:{"click":function($event){return _vm.$router.push(("/equivalences/create?type=" + _vm.equivalenceType))}}},[_vm._v("Nouvelle demande")]),(_vm.draftEquivalences.length)?_c('div',{staticClass:"title mt-10 font-weight-light"},[_vm._v(" Brouillons "),_vm._l((_vm.draftEquivalences),function(equivalence){return _c('v-card',{key:equivalence._id,staticClass:"mt-5",attrs:{"outlined":"","ripple":false,"align":"left"},on:{"click":function($event){return _vm.$router.push(
          ("/equivalences/" + (equivalence._id) + "?type=" + _vm.equivalenceType)
        )}}},[_c('v-card-title',[_c('span',{staticClass:"body-2 font-weight-light"},[_vm._v(_vm._s(_vm.getStatusLabel(equivalence.status)))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"text":"","plain":"","icon":"","ripple":false,"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.openDeleteDialog(equivalence._id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Supprimer le brouillon")])])],1),_c('v-divider'),_c('v-card-text',{staticClass:"font-weight-light"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"subtitle-1 font-weight-light mb-2"},[_vm._v(" Demande d'équivalence ")]),_vm._l((equivalence.modules),function(module,index){return _c('v-chip',{key:index,staticClass:"mr-2"},[_vm._v(_vm._s(module.basemodule.name))])})],2),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('div',[_vm._v("Dernière sauvegarde")]),_vm._v(" "+_vm._s(_vm.formatDate(equivalence.updatedAt)))])],1)],1)],1)],1)})],2):_vm._e(),(_vm.processingEquivalences.length)?_c('div',{staticClass:"title mt-10 font-weight-light"},[_vm._v(" Demandes en cours de traitement "),_vm._l((_vm.processingEquivalences),function(equivalence){return _c('v-card',{key:equivalence._id,staticClass:"mt-5",attrs:{"outlined":"","color":"grey lighten-4","ripple":false,"align":"left"},on:{"click":function($event){return _vm.$router.push(
          ("/equivalences/" + (equivalence._id) + "?type=" + _vm.equivalenceType)
        )}}},[_c('v-card-title',[_c('span',{staticClass:"body-2 font-weight-light"},[_vm._v(_vm._s(_vm.getStatusLabel(equivalence.status)))]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',{staticClass:"font-weight-light"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"subtitle-1 font-weight-light mb-2"},[_vm._v(" Demande d'équivalence ")]),_vm._l((equivalence.modules),function(module,index){return _c('v-chip',{key:index,staticClass:"mr-2"},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":"","color":"grey"}},[_vm._v("mdi-progress-clock")])],1),_vm._v(_vm._s(module.basemodule.name))],1)})],2),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('div',[_vm._v("Date de soumission")]),_vm._v(" "+_vm._s(_vm.formatDate(equivalence.submittedAt)))])],1)],1)],1)],1)})],2):_vm._e(),(_vm.pastEquivalences.length)?_c('div',{staticClass:"title mt-10 font-weight-light"},[_vm._v(" Demandes traitées "),_c('div',{staticClass:"caption font-weight-light"},[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-progress-check")]),_vm._v(" acceptée "),_c('v-icon',{staticClass:"ml-3",attrs:{"small":"","color":"orange"}},[_vm._v("mdi-progress-alert")]),_vm._v(" situation particulière "),_c('v-icon',{staticClass:"ml-3",attrs:{"small":"","color":"red"}},[_vm._v("mdi-progress-close")]),_vm._v(" refusée ")],1),_vm._l((_vm.pastEquivalences),function(equivalence){return _c('v-card',{key:equivalence._id,staticClass:"mt-5",attrs:{"outlined":"","color":"grey lighten-4","ripple":false,"align":"left"},on:{"click":function($event){return _vm.$router.push(
          ("/equivalences/" + (equivalence._id) + "?type=" + _vm.equivalenceType)
        )}}},[_c('v-card-title',[_c('span',{staticClass:"body-2 font-weight-light"},[_vm._v(_vm._s(_vm.getStatusLabel(equivalence.status)))]),_c('v-spacer')],1),_c('v-divider'),_c('v-card-text',{staticClass:"font-weight-light"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"subtitle-1 font-weight-light mb-2"},[_vm._v(" Demande d'équivalence ")]),_vm._l((equivalence.modules),function(module,index){return _c('v-chip',{key:index,staticClass:"mr-2"},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',{attrs:{"small":"","color":_vm.getStatusColor(module.managerStatus)}},[_vm._v(_vm._s(_vm.getStatusIcon(module.managerStatus)))])],1),_vm._v(_vm._s(module.basemodule.name))],1)})],2),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","sm":"6"}},[_c('div',[_vm._v("Date de traitement")]),_vm._v(" "+_vm._s(_vm.formatDate(equivalence.updatedAt)))])],1)],1)],1)],1)})],2):_vm._e(),(!_vm.pastEquivalences)?_c('div',[_vm._v("Pas de demande actuellement...")]):_vm._e(),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}]),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('v-dialog',{attrs:{"width":"500px"},on:{"click:outside":_vm.closeDialog},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline font-weight-light"},[_vm._v("Information importante")])]),_c('v-card-text',{staticClass:"font-weight-light"},[_vm._v(" Cette action est irréversible. Êtes-vous certain de vouloir supprimer ce brouillon? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.submitting,"ripple":false,"plain":""},on:{"click":_vm.closeDialog}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"text":"","loading":_vm.submitting,"ripple":false,"plain":""},on:{"click":_vm.deleteDraft}},[_vm._v(" Supprimer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }