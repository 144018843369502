import Vue from "vue";
import VueRouter from "vue-router";
import EquivalencesHome from "@/views/EquivalencesHome.vue";
import Equivalences from "@/views/Equivalences.vue";
import Details from "@/views/Details.vue";
import Create from "@/views/Create.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/equivalences",
  },
  {
    path: "/equivalences",
    name: "Equivalences",
    component: EquivalencesHome,
  },
  {
    path: "/equivalences/modules",
    name: "Equivalences details",
    component: Equivalences,
  },
  {
    path: "/equivalences/langs",
    name: "Equivalences details",
    component: Equivalences,
  },
  {
    path: "/equivalences/create",
    name: "Equivalences create",
    component: Create,
  },
  {
    path: "/equivalences/:_id",
    name: "Equivalences details",
    component: Details,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
