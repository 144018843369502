<template>
  <div class="font-weight-light" align="center">
    <div class="headline mb-10" align="center">
      Équivalences
      {{ equivalenceType === "langs" ? "langues" : "modules" }}
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }" v-if="disabled">
          <v-icon v-bind="attrs" v-on="on"> mdi-lock </v-icon>
        </template>
        <span>Hors période d'inscription</span>
      </v-tooltip>
    </div>
    <v-btn
      @click="$router.push(`/equivalences/create?type=${equivalenceType}`)"
      outlined
      block
      color="primary lighten-2"
      plain
      :ripple="false"
      :disabled="disabled"
      >Nouvelle demande</v-btn
    >
    <div class="title mt-10 font-weight-light" v-if="draftEquivalences.length">
      Brouillons
      <v-card
        @click="
          $router.push(
            `/equivalences/${equivalence._id}?type=${equivalenceType}`
          )
        "
        class="mt-5"
        v-for="equivalence in draftEquivalences"
        :key="equivalence._id"
        outlined
        :ripple="false"
        align="left"
      >
        <v-card-title>
          <span class="body-2 font-weight-light">{{
            getStatusLabel(equivalence.status)
          }}</span>
          <v-spacer></v-spacer>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                plain
                icon
                class="mt-1"
                @click.stop="openDeleteDialog(equivalence._id)"
                v-bind="attrs"
                v-on="on"
                :ripple="false"
                :disabled="disabled"
                ><v-icon>mdi-trash-can-outline</v-icon></v-btn
              >
            </template>
            <span>Supprimer le brouillon</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="font-weight-light">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6"
                ><div class="subtitle-1 font-weight-light mb-2">
                  Demande d'équivalence
                </div>
                <v-chip
                  v-for="(module, index) in equivalence.modules"
                  :key="index"
                  class="mr-2"
                  >{{ module.basemodule.name }}</v-chip
                ></v-col
              >
              <v-col cols="12" sm="6" class="text-right">
                <div>Dernière sauvegarde</div>
                {{ formatDate(equivalence.updatedAt) }}</v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>

    <div
      class="title mt-10 font-weight-light"
      v-if="processingEquivalences.length"
    >
      Demandes en cours de traitement
      <v-card
        @click="
          $router.push(
            `/equivalences/${equivalence._id}?type=${equivalenceType}`
          )
        "
        class="mt-5"
        v-for="equivalence in processingEquivalences"
        :key="equivalence._id"
        outlined
        color="grey lighten-4"
        :ripple="false"
        align="left"
      >
        <v-card-title>
          <span class="body-2 font-weight-light">{{
            getStatusLabel(equivalence.status)
          }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="font-weight-light">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6"
                ><div class="subtitle-1 font-weight-light mb-2">
                  Demande d'équivalence
                </div>
                <v-chip
                  v-for="(module, index) in equivalence.modules"
                  :key="index"
                  class="mr-2"
                >
                  <v-avatar left>
                    <v-icon small color="grey"
                      >mdi-progress-clock</v-icon
                    > </v-avatar
                  >{{ module.basemodule.name }}</v-chip
                ></v-col
              >
              <v-col cols="12" sm="6" class="text-right">
                <div>Date de soumission</div>
                {{ formatDate(equivalence.submittedAt) }}</v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>

    <div class="title mt-10 font-weight-light" v-if="pastEquivalences.length">
      Demandes traitées
      <div class="caption font-weight-light">
        <v-icon small color="green">mdi-progress-check</v-icon> acceptée
        <v-icon small class="ml-3" color="orange">mdi-progress-alert</v-icon>
        situation particulière
        <v-icon small class="ml-3" color="red">mdi-progress-close</v-icon>
        refusée
      </div>
      <v-card
        @click="
          $router.push(
            `/equivalences/${equivalence._id}?type=${equivalenceType}`
          )
        "
        class="mt-5"
        v-for="equivalence in pastEquivalences"
        :key="equivalence._id"
        outlined
        color="grey lighten-4"
        :ripple="false"
        align="left"
      >
        <v-card-title>
          <span class="body-2 font-weight-light">{{
            getStatusLabel(equivalence.status)
          }}</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="font-weight-light">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6"
                ><div class="subtitle-1 font-weight-light mb-2">
                  Demande d'équivalence
                </div>
                <v-chip
                  v-for="(module, index) in equivalence.modules"
                  :key="index"
                  class="mr-2"
                >
                  <v-avatar left>
                    <v-icon
                      small
                      :color="getStatusColor(module.managerStatus)"
                      >{{ getStatusIcon(module.managerStatus) }}</v-icon
                    > </v-avatar
                  >{{ module.basemodule.name }}</v-chip
                ></v-col
              >
              <v-col cols="12" sm="6" class="text-right">
                <div>Date de traitement</div>
                {{ formatDate(equivalence.updatedAt) }}</v-col
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="!pastEquivalences">Pas de demande actuellement...</div>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" width="500px" @click:outside="closeDialog"
      ><v-card>
        <v-card-title>
          <span class="headline font-weight-light">Information importante</span>
        </v-card-title>
        <v-card-text class="font-weight-light">
          Cette action est irréversible. Êtes-vous certain de vouloir supprimer
          ce brouillon?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="closeDialog"
            :disabled="submitting"
            :ripple="false"
            plain
          >
            Annuler
          </v-btn>
          <v-btn
            text
            @click="deleteDraft"
            :loading="submitting"
            :ripple="false"
            plain
          >
            Supprimer
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: [
    "formatDate",
    "getStatusColor",
    "getStatusIcon",
    "getStatusLabel",
    "equivalenceType",
    "disabled",
  ],
  async mounted() {
    window.addEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
    });
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/v2/equivalences?type=${this.equivalenceType}`,
    });
    this.equivalences = data;
    this.snackbar = JSON.parse(localStorage.getItem("snackbar"));
  },
  beforeDestroy() {
    window.removeEventListener("unload", () => {
      localStorage.setItem(
        "snackbar",
        JSON.stringify({
          visible: false,
          color: "",
          text: "",
        })
      );
    });
    localStorage.setItem(
      "snackbar",
      JSON.stringify({
        visible: false,
        color: "",
        text: "",
      })
    );
  },
  data: () => ({
    equivalences: [],
    deleteDialog: false,
    submitting: false,
    editedId: undefined,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
  }),
  methods: {
    openDeleteDialog(_id) {
      this.deleteDialog = true;
      this.editedId = _id;
    },
    closeDialog() {
      this.editedId = undefined;
      this.deleteDialog = false;
    },
    async deleteDraft() {
      try {
        await axios({
          method: "delete",
          url: `${process.env.VUE_APP_API_URI}/equivalences/${this.editedId}`,
        });
        this.equivalences = this.equivalences.filter(
          (e) => e._id !== this.editedId
        );
        this.closeDialog();
        this.snackbar = {
          visible: true,
          color: "success",
          text: "Le brouillon a été supprimé.",
        };
      } catch (error) {
        console.log(error);
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur lors de l'enregistrement.",
        };
      }
    },
  },
  computed: {
    draftEquivalences() {
      return this.equivalences.filter((e) => ["draft"].indexOf(e.status) > -1);
    },
    processingEquivalences() {
      return this.equivalences.filter(
        (e) => ["submitted", "valid"].indexOf(e.status) > -1
      );
    },
    pastEquivalences() {
      return this.equivalences.filter(
        (e) => ["complete", "invalid", "closed"].indexOf(e.status) > -1
      );
    },
  },
};
</script>

<style scoped>
.body-2 {
  line-height: 2.5rem;
}
</style>