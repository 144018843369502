<template>
  <v-form :disabled="disabled">
    <div class="mb-5">
      1. Sélectionnez les modules pour lesquels vous souhaitez faire une demande
      d'équivalence.
    </div>
    <v-autocomplete
      label="Modules *"
      :items="modules.filter((m) => m)"
      :item-text="
        (item) =>
          item.label
            ? `${item.label} (${item.name})`
            : `Sans titre (${item.name})`
      "
      item-value="_id"
      v-model="selectedModules"
      outlined
      dense
      multiple
    ></v-autocomplete>
    <div v-if="langModuleSelected" class="mb-5">
      <a
        :href="
          modules.length &&
          modules.map((m) => m.department.indexOf('HEG') > -1).indexOf(true) >
            -1
            ? '/heg-demande-equivalence-dispense.pdf'
            : '/ing-demande-equivalence.pdf'
        "
        target="_blank"
        >Formulaire pour l'équivalence de langues</a
      >
    </div>
    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">
      2. Sélectionnez le type d'étude et une explication permettant de justifier
      les modules sélectionnés.
    </div>

    <v-container class="pa-0">
      <v-row v-for="module in equivalence.modules" :key="module._id">
        <v-col>
          <v-alert
            outlined
            type="error"
            dense
            text
            v-if="
              module &&
              !module.url &&
              module.basemodule &&
              !module.basemodule.url
            "
          >
            <div>Le descriptif de module n'est pas encore disponible.</div>
          </v-alert>
          <v-alert
            outlined
            dense
            text
            type="warning"
            v-if="
              (module && module.url && module.validite !== module.year) ||
              (module.basemodule &&
                module.basemodule.url &&
                module.basemodule.validite !== module.basemodule.year)
            "
          >
            <div>Le descriptif de module peut être sujet à modifications.</div>
            <div>
              <a :href="module.url || module.basemodule.url" target="_blank"
                >Descriptif de module (GAPS)</a
              >
            </div>
          </v-alert>
          <v-alert
            outlined
            dense
            text
            type="success"
            v-if="
              (module && module.url && module.validite === module.year) ||
              (module.basemodule &&
                module.basemodule.url &&
                module.basemodule.validite === module.basemodule.year)
            "
          >
            <div>
              Le descriptif de module est valide pour l'année académique, les
              chances de changements est extrêmement faible (mais toujours
              possible).
            </div>
            <div>
              <a :href="module.url || module.basemodule.url" target="_blank"
                >Descriptif de module (GAPS)</a
              >
            </div>
          </v-alert>
          <div>
            <v-select
              outlined
              dense
              disabled
              :items="managerStatuses"
              label="Statut"
              :value="module.managerStatus"
              v-if="equivalence.status === 'complete'"
            ></v-select>
            <v-autocomplete
              v-model="module.studyType"
              :label="`Type d'étude pour ${
                module.name || module.basemodule.name
              } *`"
              :items="studyTypes"
              outlined
              dense
            ></v-autocomplete>
            <v-textarea
              outlined
              dense
              :label="`Explication pour ${
                module.name || module.basemodule.name
              } *`"
              v-model="module.description"
            ></v-textarea></div
        ></v-col>
      </v-row>
    </v-container>

    <v-divider class="mb-6"></v-divider>
    <div class="mb-5">
      3. Téléversez les documents demandés (Vous avez la possibilité de
      sélectionner plusieurs documents).
    </div>
    <v-file-input
      v-for="studyType in requiredDocuments"
      :key="studyType"
      :label="
        studyType !== 'Autre situation'
          ? studyType === 'Formulaire pour l\'équivalence de langues'
            ? `${studyType} *`
            : `Justificatif(s) pour ${studyType} *`
          : `Justificatif(s) pour ${studyType}`
      "
      outlined
      dense
      @change="
        (file) => {
          handleUpload(studyType, file);
        }
      "
      :value="getFilename(studyType)"
      @click:clear="removeDocument(studyType)"
      :loading="uploading[studyType]"
      accept=".pdf"
      hint="Fichier au format PDF"
      truncate-length="100"
      multiple
    ></v-file-input>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  props: ["equivalence", "disabled"],
  async mounted() {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/v2/basemodules?type=${this.$route.query.type}`,
    });
    this.modules = data.map((m) => {
      m.disabled = m.deleted;
      return m;
    });
    this.selectedModules = this.equivalence.modules.map(
      (m) => m.basemodule._id
    );
  },
  data: () => ({
    modules: [],
    selectedModules: [],
    statuses: [
      { text: "Invalide", value: "invalid" },
      { text: "En attente", value: "pending" },
      { text: "Acceptée", value: "accepted" },
      { text: "Acceptée partiellement", value: "accepted-partial" },
      { text: "Refusée", value: "rejected" },
    ],
    managerStatuses: [
      { text: "En attente", value: "pending" },
      { text: "Équivalence accordée sur le module complet", value: "accepted" },
      {
        text: "Équivalence partielle sur certaines unités",
        value: "accepted-partial",
      },
      {
        text: "Dispense accordée sur le module complet",
        value: "exemption-accepted",
      },
      {
        text: "Dispense partielle sur certaines unités",
        value: "exemption-accepted-partial",
      },
      { text: "Autre situation - panachage", value: "other" },
      { text: "Aucune équivalence accordée", value: "rejected" },
    ],
    studyTypes: [
      "HES-SO",
      "EPFL",
      "Université",
      "Autre HES",
      "Autre école suisee (ET, ES, ...)",
      "École étrangère",
      "Autre situation",
    ],
    uploading: {},
  }),
  methods: {
    async handleUpload(type, files) {
      if (files && files.length) {
        files.map(async (file) => {
          if (file && file.size) {
            this.uploading[type] = true;
            try {
              this.equivalence.documents = this.equivalence.documents.filter(
                (d) => d.type !== type
              );
              const formData = new FormData();
              formData.append("file", file);
              const { data } = await axios({
                method: "post",
                url: `${process.env.VUE_APP_API_URI}/uploads`,
                data: formData,
                headers: {
                  "content-Type": "multipart/form-data",
                },
              });
              this.equivalence.documents.push({ type, student: data.filename });
              this.uploading[type] = false;
            } catch (e) {
              this.uploading[type] = false;
              console.log(e);
            }
          }
        });
      }
    },
    removeDocument(type) {
      this.equivalence.documents = this.equivalence.documents.filter(
        (d) => d.type !== type
      );
    },
    getFilename(studyType) {
      if (
        this.equivalence.documents.filter((d) => d.type === studyType)
          .length === 1
      ) {
        return this.equivalence.documents.find((d) => d.type === studyType)
          ? new File(
              [""],
              this.equivalence.documents
                .find((d) => d.type === studyType)
                .student.substring(22),
              { type: "text/plain" }
            )
          : undefined;
      }

      if (
        this.equivalence.documents.filter((d) => d.type === studyType).length >
        1
      ) {
        return new File(
          [""],
          `${
            this.equivalence.documents.filter((d) => d.type === studyType)
              .length
          } fichiers`,
          { type: "text/plain" }
        );
      }
    },
  },
  computed: {
    requiredDocuments() {
      const requiredDocuments = [
        ...new Set(this.equivalence.modules.map((m) => m.studyType)),
      ].filter((m) => m);
      if (this.langModuleSelected) {
        requiredDocuments.push("Formulaire pour l'équivalence de langues");
      }
      return requiredDocuments;
    },
    langModuleSelected() {
      return (
        this.equivalence.modules
          .map((m) => m.lang || (m.basemodule && m.basemodule.lang))
          .indexOf(true) > -1
      );
    },
  },
  watch: {
    requiredDocuments(value) {
      this.equivalence.documents = this.equivalence.documents.filter(
        (document) => value.indexOf(document.type) > -1
      );
    },
    selectedModules(value) {
      this.equivalence.modules = this.modules
        .filter((module) => value.indexOf(module._id) > -1)
        .map((module) => {
          return (
            this.equivalence.modules.find(
              (m) => m.basemodule && m.basemodule._id === module._id
            ) || module
          );
        });
    },
  },
};
</script>