var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.equivalence)?_c('v-card',{attrs:{"outlined":"","color":_vm.equivalence.status !== 'draft' ? 'grey lighten-4' : undefined}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","small":"","plain":"","ripple":false},on:{"click":function($event){return _vm.$router.push('/equivalences')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-arrow-left")]),_vm._v("retour")],1)],1),_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.equivalence.status === 'draft')?_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"text":"","plain":"","icon":"","loading":_vm.loading.save,"disabled":_vm.equivalence.status !== 'draft' ||
                (_vm.$route.query.type === 'modules' && _vm.disabled),"ripple":false},on:{"click":_vm.save}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save-outline")])],1):_vm._e()]}}],null,false,3185326126)},[_c('span',[_vm._v("Enregistrer le brouillon")])])],1)],1)],1),_c('v-divider'),_c('v-card-title',{staticClass:"pt-10 px-10 font-weight-light"},[_vm._v("Demande d'équivalence ")]),_c('v-card-subtitle',{staticClass:"px-10"},[(
        _vm.equivalence.status === 'complete' && _vm.equivalence.decisionDocuments[0]
      )?_c('a',{staticClass:"mt-3",on:{"click":function($event){return _vm.downloadDocument(_vm.equivalence.decisionDocuments[0].name)}}},[_vm._v("decision_equivalence.pdf")]):_vm._e()]),_c('v-card-text',{staticClass:"px-10 font-weight-light"},[(_vm.equivalence)?_c('equivalence-form',{attrs:{"equivalence":_vm.equivalence,"disabled":_vm.equivalence.status !== 'draft' ||
        (this.$route.query.type === 'modules' && _vm.disabled)}}):_vm._e()],1),_c('v-divider'),(_vm.equivalence.status === 'draft')?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","plain":"","disabled":_vm.loading.save || !_vm.validForm,"ripple":false},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Soumettre la demande ")])],1):_c('v-card-actions',[_c('span',{staticClass:"body-2 font-weight-light ml-4"},[_vm._v(_vm._s(_vm.getStatusLabel(_vm.equivalence.status)))])]),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},'v-btn',attrs,false),[_vm._v(" Fermer ")])]}}],null,false,474744036),model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")]),_c('v-dialog',{attrs:{"width":"500px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline font-weight-light"},[_vm._v("Information importante")])]),_c('v-card-text',{staticClass:"font-weight-light"},[_c('div',{staticClass:"mt-3"},[_vm._v(" Une fois soumise, votre demande ne sera plus modifiable. Êtes-vous certain de vouloir soumettre votre demande? ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.loading.submit,"ripple":false,"plain":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Annuler ")]),_c('v-btn',{attrs:{"text":"","loading":_vm.loading.submit,"ripple":false,"plain":""},on:{"click":_vm.submit}},[_vm._v(" Soumettre ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }