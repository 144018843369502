<template>
  <v-card
    outlined
    v-if="equivalence"
    :color="equivalence.status !== 'draft' ? 'grey lighten-4' : undefined"
  >
    <v-container fluid>
      <v-row>
        <v-col>
          <v-btn
            text
            small
            plain
            class="mt-2"
            @click="$router.push('/equivalences')"
            :ripple="false"
            ><v-icon small class="mr-2">mdi-arrow-left</v-icon>retour</v-btn
          ></v-col
        >
        <v-col class="text-right">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                plain
                icon
                class="mt-1"
                @click="save"
                v-bind="attrs"
                v-on="on"
                :loading="loading.save"
                :disabled="
                  equivalence.status !== 'draft' ||
                  ($route.query.type === 'modules' && disabled)
                "
                v-if="equivalence.status === 'draft'"
                :ripple="false"
                ><v-icon>mdi-content-save-outline</v-icon></v-btn
              >
            </template>
            <span>Enregistrer le brouillon</span>
          </v-tooltip></v-col
        >
      </v-row>
    </v-container>
    <v-divider></v-divider>

    <v-card-title class="pt-10 px-10 font-weight-light"
      >Demande d'équivalence
    </v-card-title>
    <v-card-subtitle class="px-10">
      <a
        v-if="
          equivalence.status === 'complete' && equivalence.decisionDocuments[0]
        "
        class="mt-3"
        @click="downloadDocument(equivalence.decisionDocuments[0].name)"
        >decision_equivalence.pdf</a
      >
    </v-card-subtitle>
    <v-card-text class="px-10 font-weight-light">
      <equivalence-form
        :equivalence="equivalence"
        v-if="equivalence"
        :disabled="
          equivalence.status !== 'draft' ||
          (this.$route.query.type === 'modules' && disabled)
        "
      />
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions v-if="equivalence.status === 'draft'">
      <v-spacer></v-spacer>
      <v-btn
        text
        plain
        @click="dialog = true"
        :disabled="loading.save || !validForm"
        :ripple="false"
      >
        Soumettre la demande
      </v-btn>
    </v-card-actions>
    <v-card-actions v-else>
      <span class="body-2 font-weight-light ml-4">{{
        getStatusLabel(equivalence.status)
      }}</span>
    </v-card-actions>
    <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.visible = false">
          Fermer
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="dialog" width="500px"
      ><v-card>
        <v-card-title>
          <span class="headline font-weight-light">Information importante</span>
        </v-card-title>
        <v-card-text class="font-weight-light">
          <div class="mt-3">
            Une fois soumise, votre demande ne sera plus modifiable. Êtes-vous
            certain de vouloir soumettre votre demande?
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="dialog = false"
            :disabled="loading.submit"
            :ripple="false"
            plain
          >
            Annuler
          </v-btn>
          <v-btn
            text
            @click="submit"
            :loading="loading.submit"
            :ripple="false"
            plain
          >
            Soumettre
          </v-btn>
        </v-card-actions>
      </v-card></v-dialog
    >
  </v-card>
</template>

<script>
import axios from "axios";
import EquivalenceForm from "@/components/EquivalenceForm.vue";
import fileDownload from "js-file-download";

export default {
  name: "Details",
  props: ["getStatusColor", "getStatusIcon", "getStatusLabel", "disabled"],
  components: { EquivalenceForm },
  async mounted() {
    const { data } = await axios({
      method: "get",
      url: `${process.env.VUE_APP_API_URI}/equivalences/${this.$route.params._id}`,
    });
    this.equivalence = data;
  },
  data: () => ({
    loading: { save: false, submit: false },
    equivalence: undefined,
    snackbar: {
      visible: false,
      color: "",
      text: "",
    },
    dialog: false,
  }),
  methods: {
    async save() {
      try {
        this.loading.save = true;
        await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/equivalences/${this.equivalence._id}`,
          data: {
            ...this.equivalence,
            status: "draft",
          },
        });
        this.loading.save = false;
        this.snackbar = {
          visible: true,
          color: "success",
          text: "Le brouillon a été enregistré.",
        };
      } catch (error) {
        console.log(error);
        this.loading.save = false;
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur lors de l'enregistrement.",
        };
      }
    },
    async submit() {
      try {
        this.loading.submit = true;
        const { data } = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_API_URI}/equivalences/${this.equivalence._id}`,
          data: {
            ...this.equivalence,
            status: "submitted",
          },
        });
        this.loading.submit = false;
        this.dialog = false;
        this.equivalence = data;
        this.snackbar = {
          visible: true,
          color: "success",
          text: "Votre demande a été soumise.",
        };
      } catch (error) {
        console.log(error);
        this.loading.submit = false;
        this.snackbar = {
          visible: true,
          color: "error",
          text: "Erreur lors de la soumission.",
        };
      }
    },
    async downloadDocument(name) {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/files/${name}`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "decision_equivalence.pdf");
    },
  },
  computed: {
    validForm() {
      const studyTypes = [
        ...new Set(this.equivalence.modules.map((m) => m.studyType)),
      ].filter((m) => m && m !== "Autre situation");
      const documents = this.equivalence.documents.map((d) => d.type);
      if (
        this.equivalence.modules.map((m) => m.basemodule.lang).indexOf(true) >
        -1
      ) {
        studyTypes.push("Formulaire pour l'équivalence de langues");
      }
      const allRequiredDocumentsUploaded =
        studyTypes.length === 0 ||
        studyTypes.map((s) => documents.indexOf(s) > -1).indexOf(false) === -1;
      const validation =
        this.equivalence.modules.length > 0 &&
        this.equivalence.modules
          .map(
            (m) =>
              "studyType" in m && "description" in m && m.description.length > 0
          )
          .indexOf(false) === -1 &&
        allRequiredDocumentsUploaded;
      return validation;
    },
  },
};
</script>