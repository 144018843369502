<template>
  <v-app>
    <v-app-bar app color="grey darken-2" dark>
      <img
        src="https://storage.googleapis.com/visual-identity/logo/2020-slim.svg"
        alt="Logo HEIG-VD"
        height="70%"
        class="logo"
      />
      <div class="d-flex align-center ml-5">DEMANDES D'ÉQUIVALENCE</div>
      <v-spacer></v-spacer>
      <user-profile />
    </v-app-bar>
    <v-main>
      <v-container fill-height fluid>
        <v-row justify="center">
          <v-col cols="12" sm="12" lg="8" class="mt-10"
            ><router-view
              :formatDate="formatDate"
              :getStatusColor="getStatusColor"
              :getStatusIcon="getStatusIcon"
              :getStatusLabel="getStatusLabel"
              :disabled="!this.isOpen"
          /></v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { format, parseISO } from "date-fns";
import UserProfile from "@/components/UserProfile";
import axios from "axios";

export default {
  name: "App",
  components: { UserProfile },
  data: () => ({
    modele: "",
    isOpen: false,
  }),
  async mounted() {
    if (
      this.$keycloak.hasResourceRole("manager-dad", "equivalences.manager") ||
      this.$keycloak.hasResourceRole("manager", "equivalences.manager")
    ) {
      window.location.href = `https://equivalences-manager.blacktree.io${this.$route.path}`;
    }
    if (this.$keycloak.hasResourceRole("admin", "equivalences.admin")) {
      window.location.href = `https://equivalences-admin.blacktree.io${this.$route.path}`;
    }
    try {
      const {
        data: { modele, isOpen },
      } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/v2/students`,
      });
      this.modele = modele;
      this.isOpen = isOpen;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    formatDate(value) {
      return format(parseISO(value), "dd.MM.yyyy à HH:mm");
    },
    getStatusColor(managerStatus) {
      if (["accepted"].indexOf(managerStatus) > -1) {
        return "green";
      }
      if (["rejected"].indexOf(managerStatus) > -1) {
        return "red";
      }
      if (["rejected", "accepted"].indexOf(managerStatus) === -1) {
        return "orange";
      }
    },
    getStatusIcon(managerStatus) {
      if (["accepted"].indexOf(managerStatus) > -1) {
        return "mdi-progress-check";
      }
      if (["rejected"].indexOf(managerStatus) > -1) {
        return "mdi-progress-close";
      }
      if (["rejected", "accepted"].indexOf(managerStatus) === -1) {
        return "mdi-progress-alert";
      }
    },
    getStatusLabel(status) {
      if (["draft"].indexOf(status) > -1) {
        return "Brouillon";
      }
      if (["complete"].indexOf(status) > -1) {
        return "Terminée";
      }
      if (["invalid"].indexOf(status) > -1) {
        return "Invalide";
      }
      if (["closed"].indexOf(status) > -1) {
        return "Clos sans suite";
      }
      if (["submitted", "valid"].indexOf(status) > -1) {
        return "En cours de traitement";
      }
    },
  },
};
</script>